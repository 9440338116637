<template>
  <div>
    <div class="barra"></div>
    <div class="row justify-content-center">
      <div class="col- cl col-sm-12 col-md-6 col-lg-5 col-xl-4">
        <div class="card cardl text-center border-primary mb-3">
          <div class="card-header">
            <h5>Restablece tu contraseña</h5>
          </div>
          <div class="card-body">
            <form method="POST" action ref="form">
              <div class="form-group">
                <p>
                  Ingresa tu o correo electrónico para recuperar el acceso a tu cuenta.
                </p>
              </div>
              <div class="form-group">
                <input
                  placeholder="Correo electronico"
                  type="email"
                  name="email"
                  class="form-control"
                  v-model="email"
                  required
                />
              </div>
              <div class="alert alert-warning" v-if="res" role="alert">
                {{ res }}
              </div>
              <div class="form-group">
                <ul class="alert alert-warning" v-if="submited">
                  <li v-if="submited && !$v.email.email">
                    Correo electrónico no valido.
                  </li>
                  <li v-if="submited && !$v.email.required">
                    Se requiero un correo electrónico.
                  </li>
                  <li v-if="submited && !$v.email.maxLength">
                    Su correo electronico debe tener máximo 32 caracteres
                  </li>
                </ul>
              </div>
              <div class="form-group row">
                <div class="col">
                  <div>
                    <button
                      class="btn btn-primary btn-block btnsend"
                      @click="sendResetPassport"
                    >
                      Cambiar mi contraseña
                    </button>
                    <router-link to="/login" class="btn btn-link btn-block"
                      >Inicia sesión</router-link
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer text-muted">
            ¿Aún no tienes cuenta?
            <router-link to="/register">Registrate aquí</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email, maxLength } from "vuelidate/lib/validators";

export default {
  name: "indexResetPassword",
  components: {},
  data() {
    return {
      email: "",
      res: "",
      submited: false,
    };
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(32),
    },
  },
  methods: {
    sendResetPassport(e) {
      this.submited = true;
      e.preventDefault();
      if (this.$v.$invalid) {
        return false;
      }
      const url = this.appDomainApi1 + `email/forgot-password`;
      const params = { email: this.email };
      axios
        .post(url, params)
        .then((res) => {
          console.log(res);
          this.res =
            "Pronto recibirás un correo electrónico para restablecer tu contraseña.";
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
.barra {
  height: 100px;
}
.imgav {
  display: flex;
  align-content: center;
  justify-content: center;
}
.cAvatar {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}

.cImage {
  position: absolute;
  width: 150px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
